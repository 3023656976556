/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { reactive, toRefs } from "vue";

const state = reactive({
  imageBlob: new Blob(),
});

export default () => {
  const setBlob = (imageBlob: Blob) => {
    state.imageBlob = imageBlob;
  };

  return {
    ...toRefs(state),
    setBlob,
  };
};
