/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function useInitials() {
  function getInitials(nameString: string) {
    if (!nameString) return "";

    const names = nameString.split(" ");
    let initials: string = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return { getInitials };
}
