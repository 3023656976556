
import useInitials from "@/composables/useInitials";
import MediaPost from "@/models/mediaPostModel";
import { computed, ComputedRef, defineComponent, ref } from "vue";
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import { useConfirmDialog, onClickOutside } from "@vueuse/core";
import { jobApiService } from "@/api/mediaPosts.api";
import { useAuth } from "@/auth/useAuthService";

export default defineComponent({
  props: {
    mediaPost: {
      type: MediaPost,
      required: true,
    },
  },
  emits: ["postDeleted"],
  components: {
    DotsVerticalIcon,
  },
  setup(props, context) {
    const { getInitials } = useInitials();
    let show = ref(false);
    const { getTokenSilently, user } = useAuth();
    const dialogVisible = ref(false);

    const dialog1 = useConfirmDialog(dialogVisible);

    dialog1.onConfirm(async (result) => {
      if (result) {
        const res = await jobApiService.deletePost(
          await getAccessToken(),
          props.mediaPost.postId
        );
        context.emit("postDeleted");
      }
    });

    const isYours: ComputedRef<boolean> = computed((): boolean => {
      return user.value?.sub === props.mediaPost.postedBy.id;
    });

    const getAccessToken = async () => {
      return await getTokenSilently();
    };

    const deletePost = async () => {
      console.log("delete post");
      show.value = false;
      await dialog1.reveal();
    };

    const isOpen = () => {
      console.log("is open click");
      show.value = !show.value;
      console.log(show.value);
    };
    return {
      getInitials,
      show,
      isOpen,
      confirm,
      deletePost,
      dialogVisible,
      dialog1,
      isYours,
    };
  },
});
