<template>
  <svg width="1em" height="1em" viewBox="0 0 256 256">
    <path
      d="M216 40h-80V24a8 8 0 0 0-16 0v16H40a16 16 0 0 0-16 16v120a16 16 0 0 0 16 16h39.4l-21.6 27a8 8 0 0 0 6.2 13a7.8 7.8 0 0 0 6.2-3l29.6-37h56.4l29.6 37a7.8 7.8 0 0 0 6.2 3a8 8 0 0 0 6.2-13l-21.6-27H216a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16zm0 136H40V56h176v120z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "PhPresentation",
};
</script>
