
import { jobApiService } from "@/api/mediaPosts.api";
import MediaPost from "@/models/mediaPostModel";
import { defineComponent, onMounted, ref, watch } from "vue";
import useDateTimeFormating from "../composables/dateFormatter";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import router from "@/router";
import { useAuth } from "@/auth/useAuthService";
import { useRouter } from "vue-router";
import useInitials from "@/composables/useInitials";
import PhPresentation from "@/components/svgs/presentation.vue";
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import PostHeader from "@/components/PostHeader.vue";
export default defineComponent({
  components: {
    PlusCircleIcon,
    PhPresentation,
    PostHeader,
  },
  setup() {
    const { formatDateTime } = useDateTimeFormating();
    const { getInitials } = useInitials();
    const {
      getTokenSilently,
      loginWithRedirect,
      logout,
      isAuthenticated,
      loading,
      user,
    } = useAuth();

    let show = ref(false);
    const isOpen = () => (show.value = !show.value);

    let accessToken = "";
    const Images = ref<Array<MediaPost>>([]);
    const getAccessToken = async () => {
      if (!loading.value) {
        console.log("get token");
        return await getTokenSilently();
      } else return "";
    };
    const login = () => loginWithRedirect();
    const postDeleted = (mp: MediaPost) => {
      getPosts();
    };
    const { push } = useRouter();

    watch(loading, (currentValue, oldValue) => {
      if (currentValue === false && oldValue === true) {
        getPosts();
      }
    });

    const navigatePresentation = () => {
      push({ name: "Presentation" });
    };
    const logoutAndRedirect = () => {
      logout();
      push({ path: "/" });
    };

    const getPosts = async () => {
      console.log("start");
      if (!loading.value) {
        console.log("hei");
        let token = await getAccessToken();
        accessToken = token !== null ? token : "";
        Images.value = await jobApiService.posts(accessToken);
      }
    };
    const newPost = () => {
      router.push({ name: "Capture" });
    };
    onMounted(async () => {
      await getPosts();
    });
    return {
      getPosts,
      newPost,
      Images,
      formatDateTime,
      accessToken,
      login,
      logoutAndRedirect,
      isAuthenticated,
      loading,
      user,
      getInitials,
      navigatePresentation,
      show,
      isOpen,
      DotsVerticalIcon,
      postDeleted,
    };
  },
});
