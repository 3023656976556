
import { defineComponent, onMounted } from "vue";
import { useAuth } from "@/auth/useAuthService";
import Login from "@/views/Login.vue";
export default defineComponent({
  components: {
    Login,
  },
  setup() {
    // You can import 'loading' here and show a global loader if that's more convenient
    const { initializeAuth, loading, isAuthenticated } = useAuth();
    initializeAuth({
      domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID as string,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE as string,
      scope: "openid profile email",
    }).then();

    console.log("after init");

    onMounted(() => {
      console.log("app mounted");
    });
    return { loading, isAuthenticated };
  },
});
