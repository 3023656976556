
import { defineComponent, inject } from "vue";
import { useAuth } from "@/auth/useAuthService";

export default defineComponent({
  setup() {
    const { loginWithRedirect, isAuthenticated, loading, user } = useAuth();

    const Login = () => {
      loginWithRedirect();
    };

    return { Login, loading, user, isAuthenticated };
  },
});
