import User from "./userModel";
export default class MediaPost {
  postId!: string;
  postTitle!: string;
  postMessage!: string;
  postedDateTime!: string;
  postedBy!: User;
  mediaUri!: string;
  fileType!: string;
  expireDateTime!: string;
  contentType!: string;
}
