import { AbstractApiService } from "./abstractApiService";
// import User from "@/models/userModel";
import MediaPost from "@/models/mediaPostModel";

class JobApiService extends AbstractApiService {
  public constructor() {
    super("");
  }

  public posts(accessToken: string): Promise<MediaPost[]> {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    return this.http
      .get("", config)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public post(id: string): Promise<MediaPost> {
    return this.http
      .get(`/${id}`)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public deletePost(accessToken: string, id: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    return this.http
      .delete(`/${id}`, config)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const jobApiService: JobApiService = new JobApiService();
