/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ref, toRefs } from "vue";
import dayjs from "dayjs";

export default function useDateTimeFormating() {
  function formatDateTime(data: Date) {
    const dateTime = dayjs(data).format("DD.MM.YYYY HH:mm");
    return dateTime;
  }

  return { formatDateTime };
}
